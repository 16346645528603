// In this file, we make Angular components available to AngularJS
//
// - ! - WARNING - ! -
//
// - You have to redeclare the selector for directives, with the AngularJS convention:
// ex: for myDirective to match my-directive markup.
// The selector metadata of the downgraded Angular component is ignored.
// For simplicity, make sure what you write corresponds to the component definition,
// In the Component file:
//     selector: 'my-directive',
// here:
//  angular.module(...).directive('myDirective', ...)

import { Type } from '@angular/core';
import { DowngradedCredentialsListComponent } from '@app/widgets/lists/credentials-list/downgraded/downgraded-credentials-list.component';
import { DowngradedValuesListComponent } from '@app/widgets/lists/values-list/downgraded/downgraded-values-list.component';
import { DowngradedKeyValuesListComponent } from '@app/widgets/lists/key-values-list/downgraded/downgraded-key-values-list.component';
import { downgradeComponent } from '@angular/upgrade/static';
import { fairAny, LazyEchartComponent } from 'dku-frontend-core';
import { EdaComponent } from '@features/eda/eda.component';
import { InsightCardComponent } from '@features/eda/worksheet/cards/insight-card/insight-card.component';
import { DowngradedConnectionPropertiesListComponent } from '@app/widgets/lists/connection-properties-list/downgraded/downgraded-connection-properties-list.component';
import { WorksheetRedirectionPageComponent } from '@features/eda/pages/worksheet-redirection-page/worksheet-redirection-page.component';

function directive(ajsDirective: string, component: Type<any>, inputs?: string[], outputs?: string[]): Type<any> {
    const angularJS = (window as fairAny).angular;
    angularJS.module('dataiku').directive(ajsDirective, downgradeComponent({ component, inputs, outputs, propagateDigest: false }));
    return component;
}

export function downgradeComponents(): Array<Type<any>> {
    return [
        directive('ng2KeyValuesList', DowngradedKeyValuesListComponent),
        directive('ng2ValuesList', DowngradedValuesListComponent),
        directive('ng2CredentialsList', DowngradedCredentialsListComponent),
        directive('ng2ConnectionPropertiesList', DowngradedConnectionPropertiesListComponent),
        directive('ng2Eda', EdaComponent),
        directive('ng2EdaWorksheetRedirectionPage', WorksheetRedirectionPageComponent),
        directive('ng2EdaInsight', InsightCardComponent, ['insight', 'readOnly'], ['updated']),
        directive('ng2LazyEchart', LazyEchartComponent)
    ];
}
