<editable-list 
    [itemsFormArray]="itemsFormArray"
    [addLabel]="addLabel" 
    [sortable]="sortable"
    (onAdd)="onAdd.emit($event)" 
    (onDelete)="onDelete.emit($event)"
    (onValidityChange)="onValidityChange.emit($event)">
    <ng-template let-item="item" let-goToNext="goToNext">
        <editable-list-template (onInputEnter)="goToNext($event)">
            <editable-list-input 
                [inputControl]="item?.get([keyIdentifier])"
                (inputChange)="setSuggestions($event, keyIdentifier)"
                placeholder="{{keyPlaceholder}}" 
                [required]="keyRequired"
                [suggestions]="filteredKeySuggestions"
                (onFocus)="handleFocus($event, keyIdentifier)">
            </editable-list-input>
            <editable-list-arrow></editable-list-arrow>
            <editable-list-input
                [inputControl]="item?.get(valueIdentifier)"
                (inputChange)="setSuggestions($event, valueIdentifier)"
                placeholder="{{valuePlaceholder}}"
                [required]="valueRequired"
                [suggestions]="filteredValueSuggestions"
                (onFocus)="handleFocus($event, valueIdentifier)">
            </editable-list-input>
        </editable-list-template>
    </ng-template>
</editable-list>
