import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { IScope } from 'angular';

@Component({
    selector: 'downgraded-key-values-list',
    templateUrl: './downgraded-key-values-list.component.html',
})

/**
 * Bridge between Angular and AngularJS to use key value lists in the later.
 * Simply provides proper inputs and outputs, adapts ControlValueAccessor structure and propagates changes.
 * 
 * @example
 * // In AngularJS code
 * <ng2-key-values-list [(items)]="items" (onChange)="change()"></ng2-key-values-list>
 */
export class DowngradedKeyValuesListComponent {
    @Input() items: Array<Object>;
    @Input() sortable: boolean;
    @Output() onAdd = new EventEmitter<number>();
    @Output() onDelete = new EventEmitter<number>();
    @Output() onChange = new EventEmitter<Array<Object>>();
    @Output() itemsChange = new EventEmitter<Array<Object>>();
    @Input() addLabel: string = 'Add Key/Value';
    @Input() hasDivider: boolean = true;
    @Input() focusOnEnter: boolean = true;
    @Input() keyPlaceholder: string = 'A key';
    @Input() valuePlaceholder: string = 'A value';
    @Input() keyIdentifier: string = 'key';
    @Input() valueIdentifier: string = 'value';
    @Input() keyRequired: boolean = false;
    @Input() valueRequired: boolean = false;
    @Input() keySuggestions: string[];
    @Input() valueSuggestions: string[];
    @Input() required: boolean = false;
    @Output() onValidityChange = new EventEmitter<boolean>();

    constructor(@Inject('$rootScope') private $rootScope: IScope) { 
        this.onValidityChange.subscribe(() => $rootScope.$applyAsync());
    }

    handleChange(items: Array<Object>) {
        this.$rootScope.$applyAsync();
        this.itemsChange.emit(items);
        this.onChange.emit(items);
    }
}
