import {ContainerExecSelection} from './../containers/exec/container-exec-selection';
import {ExposedObject} from './exposed-object';
import {ObjectCustomMetadata} from './object-custom-metadata';
import {RecipeEnginesPreferenceConfig} from './recipe-engines-preference-config';
import {TaggableObjectsService} from './../server/services/taggable-objects-service';

/**
 * Generated from com.dataiku.dip.coremodel.SerializedProject
 */
export interface SerializedProject extends TaggableObjectsService._TaggableObject {
    additionalDashboardUsers: SerializedProject.AdditionalDashboardUsers;
    bundleContainerSettings: any /* TODO: Missing translation of com.dataiku.dip.projects.importexport.model.BundleContainerSettings */;
    bundleExporterSettings: any /* TODO: Missing translation of com.dataiku.dip.projects.importexport.model.BundleExporterSettings */;
    customMeta: ObjectCustomMetadata;
    dashboardAuthorizations: SerializedProject.ProjectDashboardAuthorizations;
    exposedObjects: SerializedProject.ProjectExposedObjects;
    generatingAppId: string;
    generatingAppVersion: string;
    imgColor: string;
    imgPattern: number;
    metrics: any /* TODO: Missing translation of com.dataiku.dip.metrics.ProbesSet */;
    metricsChecks: any /* TODO: Missing translation of com.dataiku.dip.metrics.ChecksSet */;
    name: string;
    owner: string;
    permissions: SerializedProject.PermissionItem[];
    projectAppType: SerializedProject.ProjectAppType;
    projectKey: string;
    projectStatus: string;
    projectType: SerializedProject.ProjectType;
    settings: SerializedProject.ProjectSettings;
    showInitials: boolean;
    tutorialId: string;
    tutorialProject: boolean;
}

export namespace SerializedProject.ReaderAuthorization {
    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ReaderAuthorization$Mode
     */
    export enum Mode {
        READ = 'READ',
        RUN = 'RUN',
        WRITE = 'WRITE'
    }
}

export namespace SerializedProject {
    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ProjectExposedObjects
     */
    export interface ProjectExposedObjects {
        objects: ExposedObject[];
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ProjectRelocationSettings
     */
    export interface ProjectRelocationSettings {
        sqlLikeRecipesInitializationMode: SerializedProject.SQLLikeRecipeInitializationMode;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ProjectWikiSettings
     */
    export interface ProjectWikiSettings {
        promoted: boolean;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ProjectSettings
     */
    export interface ProjectSettings {
        cluster: any /* TODO: Missing translation of com.dataiku.dip.cluster.ClusterSelection */;
        codeEnvs: any /* TODO: Missing translation of com.dataiku.dip.code.ProjectCodeEnvsSelection */;
        container: ContainerExecSelection;
        datasetsCreationSettings: any /* TODO: Missing translation of com.dataiku.dip.dao.GeneralSettingsDAO$ProjectDefaultDatasetCreationSettings */;
        disableAutomaticTriggers: boolean;
        flowAnchorSourcesAndSinks: boolean;
        flowBuildSettings: SerializedProject.FlowBuildSettings;
        gitCommitMode: any /* TODO: Missing translation of com.dataiku.dip.git.IProjectCommitModeService$ProjectCommitMode */;
        hiveEngineCreationSettings: SerializedProject.ProjectHiveEngineCreationSettings;
        impalaEngineCreationSettings: SerializedProject.ProjectImpalaEngineCreationSettings;
        integrations: SerializedProject.IntegrationsSettings;
        k8sCluster: any /* TODO: Missing translation of com.dataiku.dip.cluster.ClusterSelection */;
        limitsSettings: SerializedProject.LimitsSettings;
        recipeEnginesPreferences: RecipeEnginesPreferenceConfig;
        relocationSettings: SerializedProject.ProjectRelocationSettings;
        sparkEngineCreationSettings: SerializedProject.ProjectSparkEngineCreationSettings;
        useRemoteGit: boolean;
        virtualWebAppBackendSettings: any /* TODO: Missing translation of com.dataiku.dip.webapps.VirtualWebAppBackendSettings */;
        wikiSettings: SerializedProject.ProjectWikiSettings;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ProjectSparkEngineCreationSettings
     */
    export interface ProjectSparkEngineCreationSettings {
        executionConfig: string;
        executionEngine: any /* TODO: Missing translation of com.dataiku.dip.recipes.AbstractSparkRecipeParams$SparkExecutionEngine */;
        useGlobal: boolean;
        useGlobalMetastore: boolean;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$LimitsSettings
     */
    export interface LimitsSettings {
        attachmentBytes: any /* TODO: Missing translation of com.dataiku.dip.dao.GeneralSettingsDAO$SoftHardLimit */;
        memSampleBytes: any /* TODO: Missing translation of com.dataiku.dip.dao.GeneralSettingsDAO$SoftHardLimit */;
        shakerMemTableBytes: any /* TODO: Missing translation of com.dataiku.dip.dao.GeneralSettingsDAO$SoftHardLimit */;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ProjectType
     */
    export enum ProjectType {
        REGULAR = 'REGULAR',
        BUNDLE_CONTAINER = 'BUNDLE_CONTAINER'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ProjectHiveEngineCreationSettings
     */
    export interface ProjectHiveEngineCreationSettings {
        executionConfig: string;
        executionEngine: any /* TODO: Missing translation of com.dataiku.dip.recipes.code.hive.HiveRecipeMeta$HiveExecutionEngine */;
        useGlobal: boolean;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ProjectIntegration
     */
    export interface ProjectIntegration {
        active: boolean;
        hook: any /* TODO: Missing translation of com.dataiku.dip.integrations.IntegrationHook */;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$PermissionItem
     */
    export interface PermissionItem {
        admin: boolean;
        executeApp: boolean;
        exportDatasetsData: boolean;
        group: string;
        manageAdditionalDashboardUsers: boolean;
        manageDashboardAuthorizations: boolean;
        manageExposedElements: boolean;
        moderateDashboards: boolean;
        readDashboards: boolean;
        readProjectContent: boolean;
        runScenarios: boolean;
        user: string;
        writeDashboards: boolean;
        writeProjectContent: boolean;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ProjectDashboardAuthorizations
     */
    export interface ProjectDashboardAuthorizations {
        allAuthorized: boolean;
        authorizations: SerializedProject.ReaderAuthorization[];
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ProjectAppType
     */
    export enum ProjectAppType {
        REGULAR = 'REGULAR',
        APP_INSTANCE = 'APP_INSTANCE',
        APP_TEMPLATE = 'APP_TEMPLATE'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$IntegrationsSettings
     */
    export interface IntegrationsSettings {
        integrations: SerializedProject.ProjectIntegration[];
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$SQLLikeRecipeInitializationMode
     */
    export enum SQLLikeRecipeInitializationMode {
        RESOLVED_TABLE_REFERENCES = 'RESOLVED_TABLE_REFERENCES',
        VARIABILIZED_TABLE_REFERENCES = 'VARIABILIZED_TABLE_REFERENCES',
        DATASET_REFERENCES = 'DATASET_REFERENCES'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ReaderAuthorization
     */
    export interface ReaderAuthorization {
        modes: SerializedProject.ReaderAuthorization.Mode[];
        objectRef: any /* TODO: Missing translation of com.dataiku.dip.SmartObjectRef */;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$FlowBuildSettings
     */
    export interface FlowBuildSettings {
        mergeSparkPipelines: boolean;
        mergeSqlPipelines: boolean;
        pruneBeforeSparkPipelines: boolean;
        pruneBeforeSqlPipelines: boolean;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$ProjectImpalaEngineCreationSettings
     */
    export interface ProjectImpalaEngineCreationSettings {
        streamMode: boolean;
        useGlobal: boolean;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$AdditionalDashboardUsers
     */
    export interface AdditionalDashboardUsers {
        users: SerializedProject.AdditionalDashboardUser[];
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedProject$AdditionalDashboardUser
     */
    export interface AdditionalDashboardUser {
        login: string;
    }
}