/**
 * Generated from com.dataiku.dip.coremodel.InfoMessage
 */
export interface InfoMessage {
    code: string;
    codeAsObject: any /* TODO: Missing translation of com.dataiku.dip.coremodel.InfoMessage$MessageCode */;
    column: number;
    context: string;
    details: string;
    isFatal: boolean;
    line: number;
    lineTo: number;
    message: string;
    severity: InfoMessage.Severity;
    title: string;
}

export namespace InfoMessage {
    /**
     * Generated from com.dataiku.dip.coremodel.InfoMessage$FixabilityCategory
     */
    export enum FixabilityCategory {
        USER_CONFIG = 'USER_CONFIG',
        USER_CONFIG_DATASET = 'USER_CONFIG_DATASET',
        USER_CONFIG_FOLDER = 'USER_CONFIG_FOLDER',
        USER_CONFIG_OR_BUILD = 'USER_CONFIG_OR_BUILD',
        USER_CONFIG_OR_ADMIN = 'USER_CONFIG_OR_ADMIN',
        ADMIN_INSTALLATION = 'ADMIN_INSTALLATION',
        ADMIN_SETTINGS_SECURITY = 'ADMIN_SETTINGS_SECURITY',
        ADMIN_SETTINGS_CONNECTIONS = 'ADMIN_SETTINGS_CONNECTIONS',
        ADMIN_SETTINGS_CONTAINERS = 'ADMIN_SETTINGS_CONTAINERS',
        ADMIN_SETTINGS_CODEENVS = 'ADMIN_SETTINGS_CODEENVS',
        ADMIN_SETTINGS_PLUGINS = 'ADMIN_SETTINGS_PLUGINS',
        ADMIN_SETTINGS_MISSING_PLUGIN = 'ADMIN_SETTINGS_MISSING_PLUGIN',
        ADMIN_SETTINGS_USER_PROFILE = 'ADMIN_SETTINGS_USER_PROFILE',
        ADMIN_SETTINGS_MISC = 'ADMIN_SETTINGS_MISC',
        ADMIN_TROUBLESHOOTING = 'ADMIN_TROUBLESHOOTING',
        PROJECT_SECURITY = 'PROJECT_SECURITY',
        API_CALL = 'API_CALL',
        READ_FUTURE_LOG = 'READ_FUTURE_LOG',
        DATA = 'DATA',
        MISSING_APP = 'MISSING_APP',
        THIRD_PARTY_SYSTEM_CONNECTION = 'THIRD_PARTY_SYSTEM_CONNECTION',
        HADOOP_INTEGRATION = 'HADOOP_INTEGRATION',
        THIRD_PARTY_SYSTEM = 'THIRD_PARTY_SYSTEM',
        IRRELEVANT = 'IRRELEVANT',
        UNKNOWN = 'UNKNOWN'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.InfoMessage$Severity
     */
    export enum Severity {
        SUCCESS = 'SUCCESS',
        INFO = 'INFO',
        WARNING = 'WARNING',
        ERROR = 'ERROR'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.InfoMessage$InfoMessages
     */
    export interface InfoMessages {
        anyMessage: boolean;
        error: boolean;
        fatal: boolean;
        maxSeverity: InfoMessage.Severity;
        messages: InfoMessage[];
        success: boolean;
        warning: boolean;
    }
}