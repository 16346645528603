import {AppHomepageTile} from './app-homepage-tile';

/**
 * Generated from com.dataiku.dip.coremodel.AppManifest
 */
export interface AppManifest {
    description: string;
    homepageSections: AppManifest.AppHomepageSection[];
    id: string;
    imgColor: string;
    imgPattern: number;
    instanceFeatures: AppManifest.AppInstancesFeatures;
    instantiationPermission: AppManifest.AppInstantiationPermission;
    label: string;
    projectExportManifest: AppManifest.AppProjectExportManifest;
    shortDesc: string;
    showInitials: boolean;
    tags: string[];
    useAppHomepage: boolean;
    useAsRecipeSettings: AppManifest.AppUseAsRecipeSettings;
}

export namespace AppManifest {
    /**
     * Generated from com.dataiku.dip.coremodel.AppManifest$AppInstancesFeatures
     */
    export interface AppInstancesFeatures {
        showCodeNavLink: boolean;
        showFlowNavLink: boolean;
        showLabNavLink: boolean;
        showVersionControlFeatures: boolean;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppManifest$AppInstantiationPermission
     */
    export enum AppInstantiationPermission {
        EVERYBODY = 'EVERYBODY',
        USE_APP_MASTER_PERMISSIONS = 'USE_APP_MASTER_PERMISSIONS'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppManifest$AppHomepageSection
     */
    export interface AppHomepageSection {
        sectionText: string;
        sectionTitle: string;
        tiles: AppHomepageTile[];
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppManifest$AppProjectExportManifest
     */
    export interface AppProjectExportManifest {
        exportAllDatasets: boolean;
        exportAllInputDatasets: boolean;
        exportAllInputManagedFolders: boolean;
        exportAnalysisModels: boolean;
        exportGitRepository: boolean;
        exportInsightsData: boolean;
        exportManagedFS: boolean;
        exportManagedFolders: boolean;
        exportModelEvaluationStores: boolean;
        exportSavedModels: boolean;
        exportUploads: boolean;
        includedDatasetsData: any /* TODO: Missing translation of com.dataiku.dip.projects.importexport.model.ProjectExportOptions$IncludedDatasetData */[];
        includedManagedFolders: any /* TODO: Missing translation of com.dataiku.dip.projects.importexport.model.ProjectExportOptions$IncludedManagedFolder */[];
        includedModelEvaluationStores: any /* TODO: Missing translation of com.dataiku.dip.projects.importexport.model.ProjectExportOptions$IncludedModelEvaluationStore */[];
        includedSavedModels: any /* TODO: Missing translation of com.dataiku.dip.projects.importexport.model.ProjectExportOptions$IncludedSavedModel */[];
    }

    /**
     * Generated from com.dataiku.dip.coremodel.AppManifest$AppUseAsRecipeSettings
     */
    export interface AppUseAsRecipeSettings {
        category: string;
        icon: string;
        inputRoles: AppManifest.AppUseAsRecipeSettings.RoleMapping[];
        outputRoles: AppManifest.AppUseAsRecipeSettings.RoleMapping[];
        runScenarioTile: AppHomepageTile.RunScenarioTile;
        variablesEditionTile: AppHomepageTile.ProjectVariablesTile;
    }
}

export namespace AppManifest.AppUseAsRecipeSettings {
    /**
     * Generated from com.dataiku.dip.coremodel.AppManifest$AppUseAsRecipeSettings$RoleMapping
     */
    export interface RoleMapping {
        objectId: string;
        roleLabel: string;
        type: any /* TODO: Missing translation of com.dataiku.dip.dataflow.graph.FlowComputable$FCType */;
    }
}